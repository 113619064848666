* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}

a {
  text-decoration: none;
  color: #fff;
}

ul::-webkit-scrollbar-thumb {
  background-color: #5a5e61;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

ul::-webkit-scrollbar {
  width: 12px;
}

ul {
  margin: 0;
  padding: 0;
  max-height: 395px;
  border-radius: 8px;
}

li {
  list-style-type: none;
}
#root {
  background-color: #5a5e61;
}
.App {
  color: #fff;
  text-align: center;
  padding: 10px;
}

.bp-container {
  display: grid;
  height: 100%;
  grid-template-columns: 19% 30% 50%;
  grid-template-rows: repeat(2, 48vh);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.bp-details {
  padding: 15px;
  line-height: 30px;
  text-align: left;
}

.cell-height {
  border: 1px solid black;
  border-radius: 1rem;
  box-shadow: 0.15rem 0.2rem 0.15rem 0.1rem rgb(0 0 0 / 80%);
}
.cell {
  border: 1px solid black;
  border-radius: 1rem;
  box-shadow: 0.15rem 0.2rem 0.15rem 0.1rem rgb(0 0 0 / 80%);
  overflow-y: auto;
}

.bp-cell {
  width: 90%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 1rem;
  padding: 5px;
  padding-left: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.bp-cell-input {
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: #5a5e61;
  border-radius: 1rem;
  padding: 4px;
}

.ref-cell {
  border: 1px solid black;
  margin: 10px auto;
  cursor: pointer;
  border-radius: 1rem;
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.indicators {
  display: flex;
  flex-direction: column;
  overflow-y: unset;
}
.indicators-container {
  max-width: 300px;
  text-align: left;
  margin: 20px auto;
  border: 2px solid black;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
}

.indicators-link:hover {
  opacity: 0.7;
}

.details-container {
  max-width: 350px;
  margin: 20px auto;
  border: 2px solid black;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
}

.cell-i {
  padding: 10px;
}

.bp-arrow {
  transform: rotate(270deg);
  align-self: center;
  cursor: pointer;
}

.bp-arrow--show {
  display: flex;
}

.down {
  transform: rotate(90deg);
}

.bp-arrow:hover {
  opacity: 0.3;
}

.bp-childrens {
  display: none;
}

.bp-childrens_active {
  display: flex;
  gap: 10px;
}

.bp-delete {
  font-size: 20px !important;
}

.bp-edit {
  font-size: 20px !important;
}

.bp-edit:hover {
  opacity: 0.3;
}

.bp-delete:hover {
  opacity: 0.3;
}

.bp-update-button {
  font-size: 20px !important;
  align-self: center;
}

.bp-update-button:hover {
  opacity: 0.3;
  cursor: pointer;
}

.bp-add:hover {
  opacity: 0.3;
  cursor: pointer;
}

.bp-add_subprocess {
  font-size: 20px !important;
}

.block {
  display: block;
}

.cell-d {
  padding: 10px;
  font-size: 24px;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7a7f9d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5a5e61;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #5a5e61;
}

.object-item {
  border-radius: 1rem;
  border: 1px solid black;
  margin: 10px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.reference {
  overflow-y: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.references {
  max-width: 80%;
  max-height: 100%;
  /* margin: auto; */
  width: 100%;
  overflow-y: auto;
}

.references::-webkit-scrollbar {
  display: none;
}

.bp-cell-selected {
  border: 2px solid #2e7d32;
}
.objects {
  overflow-y: auto;
}
.objects-title {
  position: absolute;
  left: 40%;
  margin-top: 13px;
}

.objects-list {
  overflow-y: auto;
  min-height: 90%;
}

.objects-header {
  display: flex;
  position: relative;
  justify-content: space-between;
}

/* search bar */

button,
input {
  line-height: 1.5em;
}

input {
  color: #171717;
}

.MuiSlider-valueLabel {
  top: 55px !important;
}

.MuiSlider-valueLabel:before {
  top: -6px;
}

.search-bar {
  display: flex;
}

.search-bar input,
.search-btn,
.search-btn:before,
.search-btn:after {
  transition: all 0.25s ease-out;
}
.search-bar input,
.search-btn {
  width: 3em;
  height: 3em;
}
.search-bar input:invalid:not(:focus),
.search-btn {
  cursor: pointer;
}
.search-bar,
.search-bar input:focus,
.search-bar input:valid {
  width: 100%;
}
.search-bar input:focus,
.search-bar input:not(:focus) + .search-btn:focus {
  outline: transparent;
}
.search-bar {
  max-width: 15em;
}
.search-bar input {
  background: transparent;
  border-radius: 1.5em;
  box-shadow: 0 0 0 0.4em #171717 inset;
  padding: 0.75em;
  transform: translate(0.5em, 0.5em) scale(0.5);
  transform-origin: 100% 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.search-bar input::-webkit-search-decoration {
  -webkit-appearance: none;
}
.search-bar input:focus,
.search-bar input:valid {
  background: #fff;
  border-radius: 0.375em 0 0 0.375em;
  box-shadow: 0 0 0 0.1em #d9d9d9 inset;
  transform: scale(1);
}
.search-btn {
  background: #5a5e61;
  border-radius: 0 0.75em 0.75em 0 / 0 1.5em 1.5em 0;
  padding: 0.75em;
  position: relative;
  transform: translate(0.25em, 0.25em) rotate(45deg) scale(0.25, 0.125);
  transform-origin: 0 50%;
}
.search-btn:before,
.search-btn:after {
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
}
.search-btn:before {
  border-radius: 50%;
  box-shadow: 0 0 0 0.2em #f1f1f1 inset;
  top: 0.75em;
  left: 0.75em;
  width: 1.2em;
  height: 1.2em;
}
.search-btn:after {
  background: #f1f1f1;
  border-radius: 0 0.25em 0.25em 0;
  top: 51%;
  left: 51%;
  width: 0.75em;
  height: 0.25em;
  transform: translate(0.2em, 0) rotate(45deg);
  transform-origin: 0 50%;
}
.search-btn span {
  display: inline-block;
  overflow: hidden;
  width: 1px;
  height: 1px;
}

/* Active state */
.search-bar input:focus + .search-btn,
.search-bar input:valid + .search-btn {
  background: #5a5e61;
  border-radius: 0 0.375em 0.375em 0;
  transform: scale(1);
}
.search-bar input:focus + .search-btn:before,
.search-bar input:focus + .search-btn:after,
.search-bar input:valid + .search-btn:before,
.search-bar input:valid + .search-btn:after {
  opacity: 1;
}
.search-bar input:focus + .search-btn:hover,
.search-bar input:valid + .search-btn:hover,
.search-bar input:valid:not(:focus) + .search-btn:focus {
  background: #6f6f74;
}
.search-bar input:focus + .search-btn:active,
.search-bar input:valid + .search-btn:active {
  transform: translateY(1px);
}

@media screen and (prefers-color-scheme: dark) {
  input {
    color: #f1f1f1;
  }

  .search-bar input {
    box-shadow: 0 0 0 0.4em #f1f1f1 inset;
  }
  .search-bar input:focus,
  .search-bar input:valid {
    background: #6f6f74;
    box-shadow: 0 0 0 0.1em #3d3d3d inset;
  }
  .search-btn {
    background: #f1f1f1;
  }
}
